import { Injectable, OnInit } from "@angular/core";
import { CrudService } from "./crud.service";
import { AuthService } from "./auth.service";
import {UrlConfig} from "../url-config";

@Injectable({
  providedIn: 'root'
})
export class SchoolYearService implements OnInit {
  config: any;
  entity: any;
  payloads: any;
  currentUserSchoolId!: number;
  isTeacher!: boolean;
  constructor(private crudService: CrudService,
    private authService: AuthService) {
    this.entity = UrlConfig.schoolyear;
  }
  ngOnInit(): void {
    this.currentUserSchoolId = this.authService.identifiantEcole();
    this.isTeacher = this.authService.isTeacher();
  }

  getSchoolYears = () => this.crudService.getEntities(this.entity.list);

  updateSchoolYear(data: any) {
    this.payloads = {
      id: data.id,
      fromYear: data.fromYear,
      toYear: data.toYear,
      startDay: data.startDay,
      endDay: data.endDay,
      currentYear: data.currentYear,
    };
    console.log('payload:: ', this.payloads);
    return this.crudService.updateEntity(this.entity.update, this.payloads).pipe();
  }

  addSchoolYear(data: any) {
    this.payloads = {
      fromYear: data.fromYear,
      toYear: data.toYear,
      startDay: data.startDay,
      endDay: data.endDay,
      currentYear: data.currentYear,
    };
    return this.crudService.addEntity(this.entity.add, this.payloads).pipe();
  }

  deleteSchoolYear(id: number) {
    return this.crudService.deleteEntity(this.entity.delete,id).pipe();
  }


}
