import {Injectable, OnInit} from "@angular/core";
import {UrlConfig} from "../url-config";
import {AuthService} from "./auth.service";
import {CrudService} from "./crud.service";
import {SearchParamsCriteria} from "../models/search-params-criteria";
import {catchError, Observable, of} from "rxjs";
import {AppError} from "../types/app-error";
import {NotificationService} from "../notification.service";
import {Classe} from "../models/classe";

@Injectable({
  providedIn: 'root',
})
export class ClasseService implements OnInit {
  config: any;
  entity: any;
  payloads: any;
  currentUserSchoolId!: number;
  isTeacher!: boolean;

  constructor(private crudService: CrudService,
              private authService: AuthService,
              private notificationService: NotificationService) {
    this.entity = UrlConfig.classe;
  }

  ngOnInit(): void {
    this.currentUserSchoolId = this.authService.identifiantEcole();
    this.isTeacher = this.authService.isTeacher();
  }


  /**
   * Retourne la liste des classe en fonction  des parametres des recherches
   *
   * @param searchParams parametres de recherches
   */
  getClassroomBySearchParams(searchParams: SearchParamsCriteria): Observable<Classe[]> {
    return this.crudService.getBySearchParams(this.entity.list, searchParams).pipe(
      catchError((error: AppError) => {
        this.notificationService.handleError(error);
        return of([]);
      }),
    );
  }

  cloneClassrooms = (data: any) => this.crudService.addEntity(this.entity.clone, {
    id: data.id,
    sourceSchoolId: data.sourceSchool,
    sourceYearId: data.sourceSchoolYear,
    sourceSectionId: data.sourceSection,
    targetSchoolId: data.targetSchool,
    targetYearId: data.targetSchoolYear,
    targetSectionId: data.targetSection,
  });


  private createPayload(data: any) {
    const {
      libelle,
      code,
      hardwareId,
      targetSchool: schoolId,
      level: levelId,
      option: optionId,
      targetSchoolYear: schoolYearId,
      registre,
      amount,
    } = data;
    return {libelle, code, hardwareId, schoolId, levelId, optionId, schoolYearId, registre, amount};
  }

  updateClasse(data: any) {
    const payloads = {id: data.id, ...this.createPayload(data)};
    return this.crudService.updateEntity(this.entity.update, payloads);
  }

  addClasse(data: any) {
    const payloads = this.createPayload(data);
    return this.crudService.addEntity(this.entity.add, payloads);
  }


  ordonnerClasses(data: any[]) {
    this.payloads = {
      classesPlusNumeroOrdre: data
    };
    return this.crudService.updateEntity(this.entity.ordonner, this.payloads).pipe();
  }

  deleteClasse(id: number) {
    return this.crudService.deleteEntity(this.entity.delete, id).pipe();
  }

  search = (searchTerm: string) =>
    this.crudService.getEntities(
      this.authService.checkEndPoint(this.entity.listby.search + '/' + searchTerm),
    );
}
