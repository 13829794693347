export const WordingEn = {
  FILE_ARIANE: {
    STUDENT: {
      TITLE: 'Student',
      HOME: 'Home',
      ITEM: 'Student management',
      ITEM_ACTIVE: 'students',
    },
    INSCRIPTION: {
      TITLE: 'Registration',
      HOME: 'Home',
      ITEM: 'Student Management',
      ITEM_ACTIVE: 'Registration',
    },
    SETTING: {
      TITLE: 'Settings',
      HOME: 'Home',
      ITEM: 'Management',
      ITEM_ACTIVE: 'console',
    },
  },
  LOGIN: {
    WELCOME_MESSAGE: 'Welcome to our app!',
    USERNAME: 'Username',
    PASSWORD: 'Password',
    SIGN_IN: 'Sign In',
    FORGOT_PASSWORD: 'Forgot Password?',
  },
  SIGNUP: {
    CREATE_ACCOUNT: 'Create Account',
    EMAIL: 'Email',
    CONFIRM_PASSWORD: 'Confirm Password',
    TERMS_CONDITIONS: 'I agree to the Terms and Conditions',
    SIGN_UP: 'Sign Up',
  },
  FORGOT_PASSWORD: {
    RESET_PASSWORD: 'Reset Password',
    ENTER_EMAIL: 'Enter your email address',
    SEND_RESET_LINK: 'Send Reset Link',
  },
  FORM: {
    LABEL: {
      REG_NUMBER: 'Reg number: ',
      SEARCH_VALUE: 'Search',
      PLACEHOLDER: {
        SEARCH_VALUE: 'Search',
      },
      REQUIRED: {
        SEARCH_VALUE: 'Search value is required',
      },
    },
    DIALOG_TITLE_ADD: 'Add',
    ADD_BTN: 'Add',
    ADD_NOTES: 'Add notes',
    DIALOG_TITLE_EDIT: 'Edit',
    DIALOG_TITLE_DELETE: 'Delete',
    DIALOG_TITLE_RESTORE: 'Restore',
    DIALOG_TITLE_CRITERIA: 'More filters',
    DIALOG_TITLE_REGISTER_ONE: 'Register a student',
    DIALOG_TITLE_REGISTER_MANY: 'Register students',
    DIALOG_TITLE_DOWNLOAD: 'Download Results',
    DIALOG_TITLE_NEWS: 'Send messages',
    SAVE_BTN: 'Save',
    EDIT_BTN: 'Edit',
    DELETE_BTN: 'Delete',
    RESTORE_BTN: 'Restore',
    SEARCH_PLUS_BTN: 'filter',
    REGISTRATION_OF_MANY: 'Register students',
    CANCEL_BTN: 'Cancel',
    REFRESH_BTN: 'Refresh',
    YES_BTN: 'Yes',
    NO_BTN: 'No',
    REGISTER_BTN: 'Register',
    REGISTER_ALL_BTN: 'Register all',
    INITIALIZE_BTN: 'Initialize',
    DOWNLOAD_BTN: 'Download',
    CLONE_BTN: 'Clone',
    CONFIRMATION_TITLE: 'Confirmation',
    CONFIRMATION_MESSAGE: 'Are you sure you want to delete this line?',
    CONFIRMATION_DATA_MESSAGE: 'Are you sure you want to delete this data?',
    SEARCH: 'search',
    CONCERN_CLASS: 'The classroom',
    CONCERN_ALL_CLASS: 'All classrooms',
    CONCERN_SCHOOL: 'The school',
    CONCERN_STUDENT: 'The student',
    CONCERN_ALL_STUDENT: 'All students',
    STUDENT: {
      TITLE: {
        ADD_TITLE: 'Add a student',
      },
      MESSAGE_REQUIRED: {
        LNAME: 'Last name is required',
        FNAME: 'First name is required',
        REG_NUMBER: 'Registration number is required',
        DOB: 'Date of birth is required',
        SCHOOL: 'School is required',
        CLASS: 'Class is required',
        PARENT_PHONE_NUMBER: 'Parent\'s phone number is required',
        TYPE_REG_NUMBER: 'Registration type is required',
      },
      PLACEHOLDER: {
        LNAME: 'Enter last name',
        FNAME: 'Enter first name',
        REG_NUMBER: 'Enter registration number',
        DOB: 'Select date of birth',
        SCHOOL: 'Select school',
        CLASS: 'Select class',
        PARENT_PHONE_NUMBER: 'Enter parent\'s phone number',
        TYPE_REG_NUMBER: 'Select registration type',
      },
      LNAME: 'Last name',
      FNAME: 'First name',
      REG_NUMBER: 'Registration number',
      DOB: 'Date of birth',
      CLASS: 'Class',
      PARENT_PHONE_NUMBER: 'Parent\'s phone number',
      TYPE_REG_NUMBER: 'Registration type',
      SCHOOL: 'School',
      REGISTRE: 'Register'
    },
    REGISTRATION: {
      LABEL: {
        TITLE: 'Add a registration',
      },
      TAB_NOUVEAU: {
        LABEL: {
          TITLE: 'Register a new student',
          NOUVEAU: 'New student',
        },
        TOOLTIP: {
          NOUVEAU: 'Register a new student',
        },
        MESSAGE_REQUIRED: {
          LNAME: 'Last name is required',
          FNAME: 'First name is required',
          REG_NUMBER: 'Registration number is required',
          DOB: 'Date of birth is required',
          SCHOOL: 'School is required',
          CLASS: 'Class is required',
          PARENT_PHONE_NUMBER: 'Parent\'s phone number is required',
          TYPE_REG_NUMBER: 'Type of registration number is required',
        },
        PLACEHOLDER: {
          LNAME: 'Enter last name',
          FNAME: 'Enter first name',
          REG_NUMBER: 'Enter registration number',
          DOB: 'Select date of birth',
          SCHOOL: 'Select school',
          CLASS: 'Select class',
          PARENT_PHONE_NUMBER: 'Enter parent\'s phone number',
          TYPE_REG_NUMBER: 'Select type of registration number',
        },
      },
      TAB_CLASSE_SUIVANTE: {
        LABEL: {
          TITLE: 'Register in a higher class',
          GO_TO_NEXT_CLASS: 'Next class',
        },
        TOOLTIP: {
          GO_TO_NEXT_CLASS: 'Register in the next class',
        },
        MESSAGE_REQUIRED: {
          LNAME: 'Last name is required',
          FNAME: 'First name is required',
          REG_NUMBER: 'Registration number is required',
          DOB: 'Date of birth is required',
          SCHOOL: 'School is required',
          CLASS: 'Class is required',
          PARENT_PHONE_NUMBER: 'Parent\'s phone number is required',
          TYPE_REG_NUMBER: 'Type of registration number is required',
        },
        PLACEHOLDER: {
          LNAME: 'Enter last name',
          FNAME: 'Enter first name',
          REG_NUMBER: 'Enter registration number',
          DOB: 'Select date of birth',
          SCHOOL: 'Select school',
          CLASS: 'Select class',
          PARENT_PHONE_NUMBER: 'Enter parent\'s phone number',
          TYPE_REG_NUMBER: 'Select type of registration number',
        },
      },
      TAB_REDOUDLANT: {
        LABEL: {
          TITLE: 'Register a repeating student',
          REDOUBLANT: 'Repeating student',
        },
        TOOLTIP: {
          REDOUBLANT: 'Register a repeating student',
        },
        MESSAGE_REQUIRED: {
          LNAME: 'Last name is required',
          FNAME: 'First name is required',
          REG_NUMBER: 'Registration number is required',
          DOB: 'Date of birth is required',
          SCHOOL: 'School is required',
          CLASS: 'Class is required',
          PARENT_PHONE_NUMBER: 'Parent\'s phone number is required',
          TYPE_REG_NUMBER: 'Type of registration number is required',
        },
        PLACEHOLDER: {
          LNAME: 'Enter last name',
          FNAME: 'Enter first name',
          REG_NUMBER: 'Enter registration number',
          DOB: 'Select date of birth',
          SCHOOL: 'Select school',
          CLASS: 'Select class',
          PARENT_PHONE_NUMBER: 'Enter parent\'s phone number',
          TYPE_REG_NUMBER: 'Select type of registration number',
        },
      },
    }


  },
  DASHBOARD: {
    TITLE: 'Dashboard: ',
    GREETING: 'Hi, Welcome',
    TOTAL_STUDENT: 'Total students ',
    TOTAL_PARENT: 'Total parents',
    TOTAL_CLASSE: 'Total classes',
    TOTAL_RETARD: 'Total delays',
  },
  GRADE: {
    DEVELOPER: 'Developer',
    TEACHER: 'Teacher',
    SCHOOL_ADMIN: 'School. Admin',
    PARENT: 'Parent',
    STUDENT: 'Student',
    STAFF: 'Staff'
  },
  SIDEBAR: {
    TITLE: 'Home',
    STUDENT_MANAGEMENT: {
      TITLE: 'Student management',
      INSCRIPTION: 'Inscriptions',
      STUDENT: 'Students',
      DELAY: 'Delays',
      ABSENCE: 'Absence'
    },
    NOTE_MANAGEMENT: {
      TITLE: 'Note management',
      NOTE: 'Notes',
      RESULT: 'Results'
    },
    COMMUNICATION_MANAGEMENT: {
      TITLE: 'Communication',
      NEWS: 'News',
      EVENT: 'Events'
    },
    CONFIGURATION: {
      TITLE: 'Settings',
      ADMIN: 'Administration',
      CONSOLE: 'Console',
    }
  },
  PAGINATOR: {
    ITEMS_PER_PAGE: 'Items per page',
    NEXT_PAGE: 'Next page',
    PREVIOUS_PAGE: 'Previous page',
    FIRST_PAGE: 'First page',
    LAST_PAGE: 'Last page',
    ON: 'on'
  },
  CARD: {
    GRAPH_STAT: {
      DEFAULT_TITLE: 'Graphic',
      SCHOOL_TITLE: 'Schools Graphic',
      SCHOOL_WORK_FORCE_TITLE: 'Total workforce',
    },

    CLASSE_TITLE: 'Class',
    SCHOOL_TITLE: 'School',
    SCHOOL_YEAR_TITLE: 'School Year',
    MANAGE_ACTIVE_STUDENT_TITLE: 'Managing activated students',
    MANAGE_DESACTIVE_STUDENT_TITLE: 'Managing deactivated students',
    MANAGE_ACTIVE_INSCRIPTION_TITLE: 'Managing activated inscriptions',
    MANAGE_DESACTIVE_INSCRIPTION_TITLE: 'Managing deactivated inscriptions',
    TOGGLE_ACTIVE_TITLE: 'Show activated items',
    TOGGLE_DESACTIVE_TITLE: 'Show deactivated items',
    TOGGLE_CRITERIA_VALUE_TITLE: 'Clean up search criteria',
  },
  PLACEHOLDER: {
    SELECT_CLASSE: 'Select a class',
    SELECT_SCHOOL_YEAR: 'Select a school Year',
    SELECT_SCHOOL: 'Select a school'
  },
  MESSAGE: {
    EMPTY_DATA_SOURCE: 'No results found',
    SELECT_CLASSROOM: 'Select the classroom if available',
    LNAME_REQUIRED: 'Last name is required',
    FNAME_REQUIRED: 'First name is required',
    REGISTRATION_TYPE_REQUIRED: 'Registration type is required',
    REGISTRATION_NUMBER_REQUIRED: 'Registration number is required',
    SCHOOL_REQUIRED: 'School is required',
    CLASSE_REQUIRED: 'Class is required',
    DOB_REQUIRED: 'Date of birth is required',
    PAYMENT_STATUS_REQUIRED: 'Payment status is required',
    PAID_AMOUNT_REQUIRED: 'Min tuition fees: 0',
    SCHOOL_FEES_EXECED_AMOUNT: 'Do not exceed the outstanding balance:',
    SCHOOL_FEES_INVALID_AMOUNT: 'is an invalid amount.'
  },
  LABEL: {
    STUDENT: {
      LNAME: 'Last Name',
      FNAME: 'First Name',
      LNAME_FNAME: 'Last & First name',
      REG_TYPE: 'Registration Type',
      REG_NUMBER: 'Registration Number',
      TUTOR_PHONE: 'Parent Phone Number',
      DOB: 'Date of Birth',
      SCHOOL: 'School',
      AUTO_REG_NUMBER: 'Automatic Registration',
      MANUAL_REG_NUMBER: 'Manual Registration',
      FORMER_CLASSROOM: 'Former classroom',
      NEW_CLASSROOM: 'New classroom',
      STUDENT_CLASSROOM_STATUS: 'student status',
      LOAD_DATA_FROM: 'Load data from',
      SELECT_STUDENT: 'Select a student',
    },
    FRANCO: 'Francophone',
    ANGLO: 'Anglophone',
    TRIMESTER1: 'Trimester 1',
    TRIMESTER2: 'Trimester 2',
    TRIMESTER3: 'Trimester 3',
    SEQUENCE1: 'Sequence 1',
    SEQUENCE2: 'Sequence 2',
    SEQUENCE3: 'Sequence 3',
    SEQUENCE4: 'Sequence 4',
    SEQUENCE5: 'Sequence 5',
    SEQUENCE6: 'Sequence 6',
    IS_INITIALIZED: 'Is Initialized',
    IS_NOT_INITIALIZED: 'Not Initialized',
    ENSEIGNANT: 'Teacher',
    STAFF: 'Staff',
    IS_CURRENT: 'Is Current',
    IS_NOT_CURRENT: 'Not Current',
    IS_CHECK_IN: 'Checked In',
    IS_CHECK_OUT: 'Checked Out',
    BOTH_ARE_CHECK: 'Both Checked In and Out',
    CLASSE: 'Class',
    PAYMENT_STATUS: 'Payment status',
    PAID_AMOUNT: 'Payment of',
    ALREADY_PAID: 'Fees already paid'

  },
  DATASOURCE: {
    STUDENT: {
      AVATAR: 'Avatar',
      LAST_NAME: 'Last Name',
      FIRST_NAME: 'First Name',
      PARENT_PHONE_NUMBER: 'Tutor Phone Number',
      REG_NUMBER: 'Registration Number',
      UID: 'UID',
      PAYMENT: 'Payment',
      ACTION: 'Actions'
    }
  },

  ERROR_PAGE: {
    ERROR_404: {
      TITLE: 'Looks Like You\'re Lost',
      DESCRIPTION: 'The Page you are looking for not available! ',
      INSTRUCTION: 'Go to Dashboard',
      HELP: 'Need Help?',
    }
  },
  CONSOLE: {
    ICON: {
      EDIT: 'edit',
      DELETE: 'delete',
      INIT_AT: 'initialized',
      ADD_SECTION: 'add section',
      ADD_LEVEL: 'initialise levels',
      ADD_CLASS: 'clone classroom',
    },
    HEADER: {
      TAB_ONE_TITLE: 'Basic conf.',
      TAB_TWO_TITLE: 'Medium conf.',
      TAB_THREE_TITLE: 'High conf.',
      TAB_FOUR_TITLE: 'Complex conf.',
    },
    CARD: {
      NEWS_TITLE: 'Message',
      EVENT_TITLE: 'Events',
      NOTE_TITLE: 'Notes',
      RESULT_TITLE: 'Results',
      DELAY_TITLE: 'Delays',
      STUDENT_TITLE: 'Students',
      DETAIL_TITLE: 'Details',
      INSCRIPTION_TITLE: 'Registrations',
      ABSENCE_TITLE: 'Absences',
      SECTION_TITLE: 'Sections',
      SCHOOL_TITLE: 'Schools',
      SCHOOL_YEAR_TITLE: 'School Years',
      PROFIL_TITLE: 'Profiles',
      TRIMESTRE_TITLE: 'Trimesters',
      LEVEL_TITLE: 'Levels',
      OPTION_TITLE: 'Options',
      EVALUATION_TITLE: 'Evaluations',
      CLASSE_TITLE: 'Classes',
      COURSE_TITLE: 'Subjects',
      GROUP_TITLE: 'Groups',
      STAFF_TITLE: 'Technical Team',
      SEQUENCE_TITLE: 'Sequences',
      TEACHER_TITLE: 'Teachers',
      TUTOR_TITLE: 'Parents',
      VERIFICATION_TITLE: 'Verification',
      PROGRAM_TITLE: 'Programs',
      PLANNING_TITLE: 'Timetables',
      PLAGE_TITLE: 'Time Slots',
    }
    ,
    DIALOG: {
      CLASS: {
        TITLE: {
          ADD_TITLE: 'Add a class',
          EDIT_TITLE: 'Edit this class',
          DELETE_TITLE: 'Delete this class'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'Title is required',
          CODE: 'Code is required',
          SCHOOL: 'School is required',
          HARDWAREID: 'Hardware ID is required',
          LEVEL: 'Level is required',
          OPTION: 'Option is required',
        },
        PLACEHOLDER: {
          INTITULE: 'e.g. class name',
          CODE: 'Code',
          HARDWAREID: 'Hardware ID'
        },
        CHOOSE_CLASS: 'Select the class',
        INTITULE: 'Designation',
        HARDWAREID: 'Hardware ID',
        CODE: 'Code',
        LEVEL: 'Level',
        OPTION: 'Option',
        SCHOOL: 'School',
        INITIALIZE_COURSE: 'Initialized course status',
        REGISTRE: 'Register'
      },

      SECTION: {
        TITLE: {
          ADD_TITLE: 'Add a section',
          EDIT_TITLE: 'Edit this section',
          DELETE_TITLE: 'Delete this section'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'Title is required',
          CODE: 'Code is required',
          SCHOOL: 'School is required',
          REGISTRE: 'Register is required',
        },
        PLACEHOLDER: {
          INTITULE: 'e.g. section name',
          CODE: 'Code'
        },
        CHOOSE_SECTION: 'Select section',
        INTITULE: 'Title',
        CODE: 'Code',
        SCHOOL: 'School',
        INITIALIZE_COURSE: 'Initialized courses status',
        REGISTRE: 'Register'
      },
      GROUP: {
        TITLE: {
          ADD_TITLE: 'Add a group',
          EDIT_TITLE: 'Edit this group',
          DELETE_TITLE: 'Delete this group'
        },
        MESSAGE_REQUIRED: {
          TITLE: 'Title is required',
          CODE: 'Code is required'
        },
        PLACEHOLDER: {
          INTITULE: 'e.g. group name',
          CODE: 'Code'
        },
        CHOOSE_GROUP: 'Choose group',
        INTITULE: 'Title',
        CODE: 'Code',
      },
      QUATER: {
        TITLE: {
          ADD_TITLE: 'Add a quarter',
          EDIT_TITLE: 'Edit this quarter',
          DELETE_TITLE: 'Delete this quarter'
        },
        MESSAGE_REQUIRED: {
          TITLE: 'Title is required',
          CODE: 'Code is required'
        },
        PLACEHOLDER: {
          INTITULE: 'e.g. quarter name',
          CODE: 'Code'
        },
        CHOOSE_GROUP: 'Choose group',
        INTITULE: 'Title',
        CODE: 'Code',
      },
      PROFIL: {
        TITLE: {
          ADD_TITLE: 'Add a profil',
          EDIT_TITLE: 'Edit this profil',
          DELETE_TITLE: 'Delete this profil'
        },
        MESSAGE_REQUIRED: {
          TITLE: 'Title is required',
          INITIALIZED: 'is required'
        },
        PLACEHOLDER: {
          INTITULE: 'e.g. profil name',
          INITIALIZED: 'Is initialized'
        },
        INTITULE: 'Title',
        INITIALIZED: 'Is initialized',
      },

      LEVEL: {
        TITLE: {
          ADD_TITLE: 'Add a level',
          EDIT_TITLE: 'Edit this level',
          DELETE_TITLE: 'Delete this level'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'Title is required',
          CODE: 'Code is required',
          SECTION: 'Section is required',
        },
        PLACEHOLDER: {
          INTITULE: 'e.g. level name',
          CODE: 'Code'
        },
        CHOOSE_SECTION: 'Select section',
        INTITULE: 'Title',
        CODE: 'Code',
        SECTION: 'Section'
      },
      SCHOOL: {
        TITLE: {
          ADD_TITLE: 'Add a school',
          EDIT_TITLE: 'Edit this school',
          DELETE_TITLE: 'Delete this school',
          INIT_COURSE_TITLE: 'initialized courses',
          CLONE_CLASS_TITLE: 'Clone class',
        },
        MESSAGE_REQUIRED: {
          SECTION: 'Section type is required',
          SCHOOL_TYPE: 'School Type is required',
          ADRESS: 'Adress is required',
          PO_BOX: 'PO Box is required',
          PICTURE: 'Picture is required',
          SERVER_LINK: ' Server link is required',
          URL: 'Url is required',
          DEFAULT: 'Is  Default Type is required',
          INTITULE: 'School name is required',
          SOURCE_SCHOOL: 'School name is required',
          SOURCE_SECTION: 'Source section is required',
          TARGET_SECTION: 'Target section is required',
        },
        PLACEHOLDER: {
          ADRESS: 'e.g. adress',
          PO_BOX: 'e.g. PO Box',
          PICTURE: 'e.g. picture',
          SERVER_LINK: 'e.g. www.domaine.com',
          URL: 'e.g. url',
          INTITULE: 'e.g. school name',
          CHOOSE_SCHOOL: 'School'
        },
        INTITULE: 'School name',
        DEFAULT: 'Default type',
        URL: 'Url',
        PO_BOX: 'PO Box',
        PICTURE: 'Picture',
        SERVER_LINK: 'Http link',
        ADRESS: 'Adress',
        SECTION: 'Section',
        SCHOOL_TYPE: 'School type',
        IS_COURSE_INITIALIZE: 'are Initialized',
        IS_NOT_COURSE_INITIALIZE: 'not Initialized',
        NO_SECTION_AVAILABLE: 'No sections available.',
        CREATE_SECTION_PROMPT: 'You can create new sections in section box.',
        SOURCE_SCHOOL: 'Source school',
        TARGET_SCHOOL: 'Target school',
        SOURCE_SECTION: 'Source section',
        TARGET_SECTION: 'Target section'
      },
      SCHOOL_YEAR: {
        TITLE: {
          ADD_TITLE: 'Add a school year',
          EDIT_TITLE: 'Edit this school year',
          DELETE_TITLE: 'Delete this school year'
        },
        MESSAGE_REQUIRED: {
          FROM_YEAR: 'Start year is required',
          TO_YEAR: 'End year is required',
          CURRENT_YEAR: 'Year type is required',
          SOURCE_SCHOOL_YEAR: 'Source school is required',
        },
        PLACEHOLDER: {
          FROM_YEAR: 'e.g. YYYY',
          TO_YEAR: 'e.g. YYYY',
        },
        FROM_YEAR: 'Start year',
        TO_YEAR: 'End year',
        START_DATE: 'Start date',
        END_DATE: 'End date',
        CURRENT_YEAR: 'This year is',
        CHOOSE_SCHOOL_YEAR: 'School year',
        INTITULE: 'School year',
        SOURCE_SCHOOL_YEAR: 'Source school year',
        TARGET_SCHOOL_YEAR: 'Target school year'
      },
      SEQUENCE: {
        TITLE: {
          ADD_TITLE: 'Add a sequence',
          EDIT_TITLE: 'Edit this sequence',
          DELETE_TITLE: 'Delete this sequence'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'Title is required',
          CODE: 'Code is required',
          QUARTER: 'Quarter is required'
        },
        PLACEHOLDER: {
          INTITULE: 'e.g. sequence name',
          CODE: 'Code'
        },
        CHOOSE_SEQUENCE: 'Choose sequence',
        INTITULE: 'Title',
        CODE: 'Code',
        QUARTER: 'Quarter'
      },
      OPTION: {
        TITLE: {
          ADD_TITLE: 'Add an option',
          EDIT_TITLE: 'Edit this option',
          DELETE_TITLE: 'Delete this option'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'Title is required',
          SECTION: 'Section is required',
          LEVEL: 'Level is required'
        },
        PLACEHOLDER: {
          INTITULE: 'e.g. option name'
        },
        CHOOSE_OPTION: 'Choose option',
        INTITULE: 'Title',
        SECTION: 'Section',
        LEVEL: 'Level'
      },
      COURSE: {
        TITLE: {
          ADD_TITLE: 'Add a subject',
          EDIT_TITLE: 'Edit this subject',
          DELETE_TITLE: 'Delete this subject'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'The title is required',
          CODE: 'The code is required',
          GROUP: 'The group is required',
          INITIALIZED: 'is required'
        },
        PLACEHOLDER: {
          INTITULE: 'e.g., level name',
          CODE: 'Code'
        },
        CHOOSE_COURSE: 'Select the subject',
        INTITULE: 'Title',
        CODE: 'Code',
        GROUP: 'Group',
        INITIALIZED: 'the subject is'
      },

    },
    MENU: {
      DOWNLOAD: 'Download',
      REFRESH: 'Refresh',
      ADD: 'Add',
      EDIT: 'Edit',
      DELETE: 'Delete',
      SEARCH: 'Search',
      ASSOCIATE: 'Associate',
      ORDER: 'Order',
      Clone: 'Clone'
    },
    MESSAGE: {
      EMPTY_DATA: ' is empty!',
      EMPTY_SCHOOL_DATA: 'The school content is empty.',
    }
  }



  // Ajoutez d'autres sections et constantes au besoin
};
